import React, { useState, useEffect } from "react";
import MeetingsContainerHeader from "./MeetingsContainerHeader";
import MeetingsList from "./MeetingsList";
import dev_meetings_list from "../../../assets/sampleStuffForDevelopmentAid/meetingCards.json";
import { Input, Modal, Flex, Spin, notification } from "antd";
import apiDetails from "../../../assets/apiDetails/apiDetails.json";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Space, TimePicker } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import "../../../CSS/meetings/MeetingsModal.css";


dayjs.extend(customParseFormat);

const currentDate = new Date();

const formatTimeTo12Hours = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

const parseTimeString = (timeString) => {
    console.log("timeString = ", timeString);
    const timeParts = timeString.match(/(\d+):(\d+)\s*(AM|PM)/i);
    if (!timeParts) return formatTimeTo12Hours(currentDate);
    let [_, hour, minute, ampm] = timeParts;
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    if (ampm.toUpperCase() === 'PM' && hour !== 12) hour += 12;
    if (ampm.toUpperCase() === 'AM' && hour === 12) hour = 0;

    const date = new Date();
    date.setHours(hour, minute, 0, 0);
    // console.log("parseTimeString date = ", date);
    const returned_parsed_formatted_time = formatTimeTo12Hours(date);
    // console.log("returned_parsed_formatted_time date = ", returned_parsed_formatted_time);
    return returned_parsed_formatted_time;
};

// Get current time in 'HH:mm A' format
const currentTime = formatTimeTo12Hours(currentDate);
// console.log("typeof timeString = ", typeof currentTime, " typeof timeString !== 'string' = ", typeof timeString !== 'string', " valid = ", !currentTime.trim())

const emptyMeetingObject = {
    "start_time": currentTime,
    "end-time": "",
    "date": dayjs(),
    "title": "",
    "company-name": "",
    "participants": "",
    "emails": "",
    "description": "",
    "meeting_link": ""
};

const MeetingsPageContainer = ({ user_details }) => {
    const [initialNumberOfMeetings, updateNumberOfMeetings] = useState(dev_meetings_list);
    const [initialNumberOfMeetingsFromAPI, setMeetingsListWithNewData] = useState([]);
    const [allProfessionalsList, setAllProfessionalsList] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(false); // New state for editability
    const [professionalSearchInput, setProfessionalSearchInput] = useState("");
    const [addedProfessionalNames, setAddedProfessionalNames] = useState([]);

    // state of the view mode of the meeting modal
    const [meetingModalViewMode, setMeetingModalViewMode] = useState("readonly");

    // state for the fields in the meeting form
    const [meetingFormTitle, setMeetingFormTitle] = useState("");
    const [meetingFormEmailsList, setMeetingFormEmailsList] = useState("");
    const [meetingFormDate, setMeetingFormDate] = useState("");
    const [meetingFormStartTime, setMeetingFormStartTime] = useState("");
    const [meetingFormStopTime, setMeetingFormStopTime] = useState("");
    const [meetingFormDescription, setMeetingFormDescription] = useState("");
    const [meetingLink, setMeetingLink] = useState("");
    const [completeMeetingDataObject, setCompleteMeetingDataObject] = useState({});

    const [incomingMeetingData, setIncomingMeetingData] = useState({});
    const [incomingMeetingID, setIncomingMeetingID] = useState(null);
    const [loading, setLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification();

    // opening the modal to create a new meeting
    const openNewMeetingModalCallback = () => {
        // openMeetingModal("sending data in to the modal being opened");
        setMeetingModalViewMode("new");
        setMeetingModalViewModeDisplayData(emptyMeetingObject);
        showModal();
    };

    // opening the modal to view or edit a meeting 
    const openViewMeetingModalFromMeetingCard = (id, index, incomingData) => {
        // alert(`opening modal now ${incomingData}`);
        // console.log("meeting data coming in from the Meeting Card File -- ", incomingData);
        async function executeApiCalls() {
            await Promise.all([
                viewMeeting(id, index, incomingData)
            ]);
            setLoading(false);
            setMeetingModalViewMode("view");
            showModal();
        }
        setLoading(true);
        executeApiCalls();
    };

    const viewMeeting = async (meetingID, meetingIndex, meetingData) => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const get_single_meeting_data = "meetings";
            const fetch_url = `${url_domain}/${version_path}/${get_single_meeting_data}/${meetingID}`;

            const response = await fetch(fetch_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                }
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the viewMeeting API call response.json() parsing function");
            // console.log(data);

            if (data && data.data) {
                // console.log("checking access control - 1");
                const rerievedMeetingObject = {
                    "start_time": data.data.start_time ? data.data.start_time : "",
                    "end_time": data.data.end_time ? data.data.end_time : "",
                    "date": data.data.date ? data.data.date : "",
                    "title": data.data.title ? data.data.title : "",
                    "emails": data.data.participants ? data.data.participants.map(participant => participant.user.email).join(', ') : "",
                    "description": data.data.description ? data.data.description : "",
                    "meeting_link": data.data.meeting_link
                };
                // console.log("checking access control - 2", meetingData);
                setMeetingModalViewModeDisplayData(rerievedMeetingObject);
                meetingData.emails = rerievedMeetingObject.emails;
                setIncomingMeetingData(meetingData);
                setIncomingMeetingID(meetingID);
                setTheAddedProfessionalNamesState(rerievedMeetingObject.emails);
                setMeetingLink(data.data.meeting_link);
                setCompleteMeetingDataObject(data.data);
            }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const showModal = () => {
        setIsModalOpen(true);
        setIsEditable(false); // Reset editability when the modal is opened
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const toggleEdit = () => {
        setIsEditable((prevState) => !prevState); // Toggle editability on button click
    };

    const setMeetingModalViewModeDisplayData = (meetingData) => {
        // console.log("meeting data when edit mode is being cancelled ", meetingData);
        setMeetingFormTitle(meetingData["title"]);
        setMeetingFormEmailsList(meetingData["emails"]);
        setMeetingFormDate(meetingData["date"]);

        // Parse and validate initial time string
        console.log("meetingData = ", meetingData);
        const initialParsedTime = parseTimeString(meetingData["start_time"]);
        console.log("initialParsedTime = ", initialParsedTime);
        setMeetingFormStartTime(initialParsedTime);

        setMeetingFormStopTime(meetingData["end_time"]);
        setMeetingFormDescription(meetingData["description"]);
        setMeetingLink(meetingData["meeting_link"]);
    }

    const addNewMeetingOrSaveChangesToTheMeeting = () => {
        // let updated_meeting_list = [...initialNumberOfMeetingsFromAPI];
        const newMeetingObject = {
            "start_time": meetingFormStartTime,
            "end_time": meetingFormStopTime,
            "date": meetingFormDate,
            "title": meetingFormTitle,
            "emails": meetingFormEmailsList,
            "description": meetingFormDescription
        };

        if (addedProfessionalNames.length === 0) {
            openErrorNotification({ message: "Please select a professional to schedule a meeting with." });
            return;
        }

        if (meetingFormTitle.length === 0) {
            openErrorNotification({ message: "Please specify the meeting title." });
            return;
        }

        if (meetingFormDescription.length === 0) {
            openErrorNotification({ message: "Please describe the agenda of the meeting." });
            return;
        }

        if (meetingModalViewMode === "new") {
            // updated_meeting_list.push(newMeetingObject);
            // updateNumberOfMeetings(updated_meeting_list);
            async function executeApiCalls() {
                await Promise.all([
                    createNewMeeting()
                ]);
                setLoading(false);
                openSuccessNotification({ message: "Meeting Created Successfully" });
                // console.log("initialNumberOfMeetingsFromAPI data after creating a new meeting", initialNumberOfMeetingsFromAPI);
                // setMeetingModalViewModeDisplayData(newMeetingObject);
                setMeetingModalViewMode("view");
            }
            setLoading(true);
            executeApiCalls();
        } else if (meetingModalViewMode === "edit") {
            // updated_meeting_list[incomingMeetingID]["title"] = meetingFormTitle;
            // updated_meeting_list[incomingMeetingID]["emails"] = meetingFormEmailsList;
            // updated_meeting_list[incomingMeetingID]["date"] = meetingFormDate;
            // updated_meeting_list[incomingMeetingID]["time"] = meetingFormStartTime;
            // updated_meeting_list[incomingMeetingID]["end_time"] = meetingFormStopTime;
            // updated_meeting_list[incomingMeetingID]["description"] = meetingFormDescription;
            // updateNumberOfMeetings(updated_meeting_list);
            // setMeetingModalViewModeDisplayData(newMeetingObject);
            // setMeetingModalViewMode("view");
            async function executeApiCalls() {
                await Promise.all([
                    updateMeeting()
                ]);
                setLoading(false);
                openSuccessNotification({ message: "Updated Meeting Successfully" });
                // console.log("initialNumberOfMeetingsFromAPI data after creating a new meeting", initialNumberOfMeetingsFromAPI);
                // setMeetingModalViewModeDisplayData(newMeetingObject);
                setMeetingModalViewMode("view");
            }
            setLoading(true);
            executeApiCalls();
        }
    };

    const updateMeeting = async () => {
        try {
            const professionalEmails = addedProfessionalNames.map(professional => professional.email.trim());
            const apiJSONBody = {
                start_time: meetingFormStartTime,
                end_time: meetingFormStopTime,
                date: meetingFormDate,
                title: meetingFormTitle,
                emails: professionalEmails,
                description: meetingFormDescription
            };

            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const update_meetings_path = "meetings";
            const meeting_id_to_update = incomingMeetingID;
            const fetch_url = `${url_domain}/${version_path}/${update_meetings_path}/${meeting_id_to_update}`;

            const response = await fetch(fetch_url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the send message between user and professional API call response.json() parsing function");
            // console.log(data);

            if (data && data.data && typeof data.data === "object" && !Array.isArray(data.data)) {
                let updated_meeting_list = [...initialNumberOfMeetingsFromAPI];
                const existing_meeting_index = updated_meeting_list.findIndex(obj => obj.id === data.data.id) || null;
                const dateTime = data.data.date;
                const dateOnly = dateTime.split('T')[0]; // Splits at 'T' and takes the first part
                data.data.date = dateOnly;
                data.data.emails = data.data.participants ? data.data.participants.map(participant => participant.user.email).join(', ') : "";
                if (existing_meeting_index !== -1) {
                    updated_meeting_list[existing_meeting_index] = data.data;
                }
                setIncomingMeetingID(data.data.id);
                setIncomingMeetingData(data.data);
                setMeetingModalViewModeDisplayData(data.data);
                setMeetingsListWithNewData(updated_meeting_list);
            }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const createNewMeeting = async () => {
        try {
            const professionalEmails = addedProfessionalNames.map(professional => professional.email.trim());
            const apiJSONBody = {
                start_time: meetingFormStartTime,
                end_time: meetingFormStartTime,
                date: meetingFormDate,
                title: meetingFormTitle,
                emails: professionalEmails,
                description: meetingFormDescription
            };

            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const create_new_meetings = "meetings/create";
            const fetch_url = `${url_domain}/${version_path}/${create_new_meetings}`;

            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the send message between user and professional API call response.json() parsing function");
            // console.log(data);

            if (data && data.data && typeof data.data === "object" && !Array.isArray(data.data)) {
                let updated_meeting_list = [...initialNumberOfMeetingsFromAPI];
                const dateTime = data.data.date;
                const dateOnly = dateTime.split('T')[0]; // Splits at 'T' and takes the first part
                data.data.date = dateOnly;
                data.data.emails = data.data.participants ? data.data.participants.map(participant => participant.user.email).join(', ') : "";
                updated_meeting_list.push(data.data);
                setIncomingMeetingID(data.data.id);
                setIncomingMeetingData(data.data);
                setMeetingModalViewModeDisplayData(data.data);
                setMeetingsListWithNewData(updated_meeting_list);
                setTheAddedProfessionalNamesState(data.data.emails)
            }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const handleMeetingModalFormCancelButtonClick = () => {
        if (meetingModalViewMode === "new") {
            setIsModalOpen(false);
            setAddedProfessionalNames([]);
        } else if (meetingModalViewMode === "edit") {
            setMeetingModalViewModeDisplayData(incomingMeetingData);
            setMeetingModalViewMode("view");
        }
    }

    const transitionMeetingModalToEditMode = () => {
        // console.log("editing meeting is = ", incomingMeetingID);
        setMeetingModalViewMode("edit");
    };

    useEffect(() => {
        // Only call fetchData once, ensuring no duplicate calls or errors.
        const fetchData = async () => {
            try {
                setLoading(true);
                await fetchAllMeetings();
                await fetchAllBusinessUsers();
            } catch (error) {
                // openErrorNotification(error, "Failed to load meetings");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchAllBusinessUsers = async () => {
        const url_domain = apiDetails.domain;
        const version_path = apiDetails.version_path;
        const get_all_business_users = "all-business-accounts";
        const fetch_url = `${url_domain}/${version_path}/${get_all_business_users}`;
        const response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user_details.access_token}`
            }
        });

        if (response.ok) {
            const data = await response.json();
            // console.log("setAllProfessionalsList = ", data);
            if (data && data.data && Array.isArray(data.data)) {
                setAllProfessionalsList(data.data);
                console.log("setAllProfessionalsList = ", data.data);
            }
        }
    }

    const fetchAllMeetings = async () => {
        const url_domain = apiDetails.domain;
        const version_path = apiDetails.version_path;
        const get_meetings_list = "meetings";
        const fetch_url = `${url_domain}/${version_path}/${get_meetings_list}`;
        const response = await fetch(fetch_url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user_details.access_token}`
            }
        });

        // console.log("response from the get all assessment API call ");
        // console.log(response);

        if (!response.ok) {
            const errorData = await response.json();
            openErrorNotification(errorData, "Failed to load meetings");
            // throw new Error(errorData);
        }

        const data = await response.json();
        // console.log("response data from the get all meetings API call response.json() parsing function");
        // console.log(data);

        if (data && data.data && Array.isArray(data.data)) {
            setMeetingsListWithNewData(data.data);
        }

    };

    const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: errorTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const openSuccessNotification = (notificationParameters, successTitle = "Success") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["success"]({
            message: successTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 30,
        });
    };

    const dateFormat = 'YYYY-MM-DD';
    // Minimum date set to today
    const minDate = dayjs().format(dateFormat);

    const timeFormat = 'HH:mm A';

    const onChangeMeetingDate = (date, dateString) => {
        // console.log(date, dateString);
        setMeetingFormDate(date);
    };

    const onChangeTime = (time, timeString) => {
        console.log("onChangeTime time = ", time);
        if (!time) {
            console.log('Invalid time');
            return;
        }

        // Extract hours and minutes from the time object
        const hours = time.$H; // This represents the hour (24-hour format)
        const minutes = time.$m; // This represents the minutes

        // Convert to 12-hour format and determine AM/PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12; // Convert to 12-hour format
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed

        // Construct the formatted time string
        const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
        setMeetingFormStartTime(formattedTime);
    };

    // Function to handle pressing Enter in the input field
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && professionalSearchInput.trim()) {
            // Find the professional by name
            const professional = allProfessionalsList.find((p) => p.name.toLowerCase() === professionalSearchInput.trim().toLowerCase());
            // console.log("professionalSearchInput = ", professional);
            if (professional && !addedProfessionalNames.some((p) => p.id === professional.id)) {
                // Add the professional to the list if not already added
                setAddedProfessionalNames([...addedProfessionalNames, professional]);
            }
            setProfessionalSearchInput('');
        }
    };

    // Function to handle deletion of a name
    const removeProfessionalNameFromMeeting = (id) => {
        setAddedProfessionalNames(addedProfessionalNames.filter((p) => p.id !== id));

        async function executeApiCalls() {
            await Promise.all([
                removeProfessionalParticipant(id)
            ]);
            setLoading(false);
            // openSuccessNotification({ message: "Meeting Created Successfully" });
            // // console.log("initialNumberOfMeetingsFromAPI data after creating a new meeting", initialNumberOfMeetingsFromAPI);
            // // setMeetingModalViewModeDisplayData(newMeetingObject);
            // setMeetingModalViewMode("view");
        }
        setLoading(true);
        executeApiCalls();
    };

    const removeProfessionalParticipant = async (professionalID) => {
        try {
            const professional = allProfessionalsList.find((p) => p.id === professionalID);
            const apiJSONBody = {
                email: professional.email
            };

            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const meetings = "meetings";
            const remove_participant = "remove-participant";
            const fetch_url = `${url_domain}/${version_path}/${meetings}/${incomingMeetingID}/${remove_participant}`;

            const response = await fetch(fetch_url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // // Check if the response status is not acceptable
            // if (!response.ok) {
            //     const error_data = await response.json();
            //     // console.log("error_data", error_data);
            //     openErrorNotification(error_data);
            //     throw new Error(`HTTP error! status: ${response.status}`);
            // }

            // const data = await response.json();
            // // console.log("response from the send message between user and professional API call response.json() parsing function");
            // // console.log(data);

            // if (data && data.data && typeof data.data === "object" && !Array.isArray(data.data)) {
            //     let updated_meeting_list = [...initialNumberOfMeetingsFromAPI];
            //     const dateTime = data.data.date;
            //     const dateOnly = dateTime.split('T')[0]; // Splits at 'T' and takes the first part
            //     data.data.date = dateOnly;
            //     data.data.emails = data.data.participants ? data.data.participants.map(participant => participant.user.email).join(', ') : "";
            //     updated_meeting_list.push(data.data);
            //     setIncomingMeetingID(data.data.id);
            //     setIncomingMeetingData(data.data);
            //     setMeetingModalViewModeDisplayData(data.data);
            //     setMeetingsListWithNewData(updated_meeting_list);
            // }

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const setTheAddedProfessionalNamesState = (emails) => {
        // Use filter to find professionals whose emails match the ones in the provided list
        const matchingProfessionals = allProfessionalsList.filter(professional => emails.includes(professional.email));
        console.log("matchingProfessionals = ", matchingProfessionals);
        setAddedProfessionalNames(matchingProfessionals);
    };

    const handleMeetingModalFormDeleteButtonClick = () => {
        setIsDeleteModalOpen(true);
    };

    const handleDeleteModalNoButton = () => {
        setIsDeleteModalOpen(false);
    };

    const handleDeleteModalYesButton = () => {

        async function executeApiCalls() {
            await Promise.all([
                deleteMeeting()
            ]);
            setLoading(false);
            setIsDeleteModalOpen(false);
            setIsModalOpen(false);
            // // console.log("initialNumberOfMeetingsFromAPI data after creating a new meeting", initialNumberOfMeetingsFromAPI);
            // // setMeetingModalViewModeDisplayData(newMeetingObject);
            // setMeetingModalViewMode("view");
        }
        setLoading(true);
        executeApiCalls();
    };

    const deleteMeeting = async () => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const meetings = "meetings";
            const fetch_url = `${url_domain}/${version_path}/${meetings}/${incomingMeetingID}`;

            const response = await fetch(fetch_url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.access_token}`
                }
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            openSuccessNotification({ message: "Meeting Deleted Successfully" });

            let updated_meeting_list = [...initialNumberOfMeetingsFromAPI];
            const filteredMeetingList = updated_meeting_list.filter(obj => obj.id !== incomingMeetingID);

            setMeetingsListWithNewData(filteredMeetingList);
            setAddedProfessionalNames([]);

        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
            // openErrorNotification({mesage: "Meeting could not be deleted. Expected Error Occured. "});
        }
    };

    return (
        <div>
            {contextHolder}
            {(loading) ? (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            ) : (
                <>
                    <MeetingsContainerHeader openNewMeetingModalCallback={openNewMeetingModalCallback} />
                    <MeetingsList
                        dev_meetings_list={initialNumberOfMeetingsFromAPI}
                        openViewMeetingModalFromMeetingCard={openViewMeetingModalFromMeetingCard}
                    />
                    <Modal
                        centered
                        open={isModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={904}
                        closable={(meetingModalViewMode === "view") ? true : false}
                        maskClosable={false}
                        footer={null}
                        className="meeting-modal-container"
                    >
                        <div className="complete-meeting-modal-container">
                            {/* {(meetingModalViewMode === "view") && (
                                <div className="meetingEditButton" onClick={transitionMeetingModalToEditMode}>
                                    Edit
                                </div>
                            )} */}

                            <div className="meetingFormContainer">
                                <div className="meetingTitleLabel">Meeting Title?</div>
                                <div className="meetingTitleInput">
                                    {(meetingModalViewMode === "view") ? (
                                        <div className="meetingTitleValueInViewMode">{meetingFormTitle}</div>
                                        // <div className="meetingTitleValueInViewMode">{completeMeetingDataObject?.title || ""}</div>
                                    ) : (
                                        <input
                                            type="text"
                                            className="meetingTitleInputElement"
                                            placeholder="Enter meeting title"
                                            value={meetingFormTitle}
                                            onChange={(e) => setMeetingFormTitle(e.target.value)}
                                        />
                                    )}
                                </div>

                                <div className="inviteParticipantsLabel">Search for professional business name and click enter to add</div>
                                <div className="listOfEmailsInput">
                                    {(meetingModalViewMode === "view") ? (
                                        <div className="listOfEmailsValueInViewMode">{meetingFormEmailsList}</div>
                                        // <div className="listOfEmailsValueInViewMode">{completeMeetingDataObject.participants ? completeMeetingDataObject.participants.map(participant => participant.user.email).join(', ') : ""}</div>
                                    ) : (
                                        // <Input.TextArea
                                        //     className='input-text-area-for-list-of-emails'
                                        //     rows={3}
                                        //     placeholder="Enter emails of the people whom you want to invite to this meeting"
                                        //     value={meetingFormEmailsList}
                                        //     onChange={(e) => setMeetingFormEmailsList(e.target.value)}
                                        // />

                                        <div className="searchContainerForProfessionalsContainer">
                                            <input
                                                type="text"
                                                placeholder="Search Professional Name"
                                                class="professionals-search-input"
                                                value={professionalSearchInput}
                                                onChange={(e) => setProfessionalSearchInput(e.target.value)}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    )}
                                </div>

                                {(meetingModalViewMode === "new" || meetingModalViewMode === "edit") && (
                                    <div className="added-professional-names-container" style={{ display: 'flex' }}>
                                        <strong style={{ marginRight: "10px" }}>Added names:</strong>
                                        <div className="added-professional-names-wrapper" style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                                            {addedProfessionalNames.map((professional) => (
                                                <div class="added-professional-tag" key={professional.id}>
                                                    <span>{professional.name}</span>
                                                    <button class="added-professional-tag-close-btn" onClick={() => removeProfessionalNameFromMeeting(professional.id)}>&times;</button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                <div className="listOfOtherMeetingDetails">
                                    <div className="dateOfMeetingContainer">
                                        <div className="dateOfMeetingLabel">Date of meeting</div>
                                        {(meetingModalViewMode === "view") ? (
                                            <div className="listOfOtherMeetingDetailsValueInViewMode">{meetingFormDate}</div>
                                            // <div className="listOfOtherMeetingDetailsValueInViewMode">{completeMeetingDataObject?.date || ""}</div>
                                        ) : (
                                            // <input
                                            //     type="text"
                                            //     className="listOfOtherMeetingDetailsTextInput"
                                            //     value={meetingFormDate}
                                            //     onChange={(e) => setMeetingFormDate(e.target.value)}
                                            // />
                                            <DatePicker
                                                onChange={onChangeMeetingDate}
                                                needConfirm
                                                value={dayjs(meetingFormDate, dateFormat)}
                                                minDate={dayjs(minDate, dateFormat)}
                                                size="medium"
                                            />
                                        )}
                                    </div>

                                    <div className="meetingStartTimeContainer">
                                        <div className="meetingStartTimeLabel">Meeting start time</div>
                                        {(meetingModalViewMode === "view") ? (
                                            <div className="listOfOtherMeetingDetailsValueInViewMode">{meetingFormStartTime}</div>
                                            // <div className="listOfOtherMeetingDetailsValueInViewMode">{completeMeetingDataObject?.start_time || ""}</div>
                                        ) : (
                                            // <input
                                            //     type="text"
                                            //     className="listOfOtherMeetingDetailsTextInput"
                                            //     value={meetingFormStartTime}
                                            //     onChange={(e) => setMeetingFormStartTime(e.target.value)}
                                            // />
                                            <TimePicker
                                                use12Hours
                                                format="h:mm A"
                                                onChange={onChangeTime}
                                                value={dayjs(meetingFormStartTime, timeFormat)}
                                                size="medium"
                                            />
                                        )}
                                    </div>


                                    {(meetingModalViewMode === "view") && (
                                        <div className="meetingStopTimeContainer">
                                            <div className="meetingStopTimeLabel">Meeting Link</div>
                                            <div className="listOfOtherMeetingDetailsValueInViewMode">
                                                <a href={meetingLink} target="_blank">{meetingLink}</a>
                                                {/* <a href={meetingLink} target="_blank">{completeMeetingDataObject?.meeting_link || ""}</a> */}
                                            </div>
                                        </div>
                                    )}


                                    {/* <div className="meetingStopTimeContainer">
                                        <div className="meetingStopTimeLabel">Meeting stop time</div>
                                        {(meetingModalViewMode === "view") ? (
                                            <div className="listOfOtherMeetingDetailsValueInViewMode">{meetingFormStopTime}</div>
                                        ) : (
                                            <input
                                                type="text"
                                                className="listOfOtherMeetingDetailsTextInput"
                                                value={meetingFormStopTime}
                                                onChange={(e) => setMeetingFormStopTime(e.target.value)}
                                            />
                                        )}
                                    </div> */}
                                </div>

                                <div className="meetingDescriptionLabel">Description</div>
                                <div className="meetingDescriptionInput">
                                    {(meetingModalViewMode === "view") ? (
                                        <div className="meetingDescriptionInputValueInViewMode">{meetingFormDescription}</div>
                                        // <div className="meetingDescriptionInputValueInViewMode">{completeMeetingDataObject?.description || ""}</div>
                                    ) : (
                                        <Input.TextArea
                                            className='input-text-area-for-meeting-description'
                                            rows={4}
                                            placeholder="Enter your message here"
                                            value={meetingFormDescription}
                                            onChange={(e) => setMeetingFormDescription(e.target.value)}
                                        />
                                    )}

                                </div>

                                <div className="meetingModalEditViewActionButtons">
                                    {(meetingModalViewMode === "new" || meetingModalViewMode === "edit") && (
                                        <>
                                            <div className="meetingSaveButton" onClick={addNewMeetingOrSaveChangesToTheMeeting}>
                                                Save
                                            </div>
                                            <div className="meetingCancelButton" onClick={handleMeetingModalFormCancelButtonClick}>
                                                Cancel
                                            </div>
                                        </>
                                    )}

                                    {(meetingModalViewMode === "view") && (
                                        <>
                                            {/* meetingEditButton */}
                                            <div className="meetingEditButton" onClick={transitionMeetingModalToEditMode}>
                                                Edit
                                            </div>
                                            <div className="meetingCancelButton" onClick={handleMeetingModalFormDeleteButtonClick}>
                                                Delete
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        centered
                        open={isDeleteModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        width={600}
                        closable={false}
                        maskClosable={false}
                        footer={null}
                        className="meeting-modal-container"
                    >
                        <div className="delete-modal-container">
                            <div className="delete-modal-container-text">Are you sure you want to delete this meeting?</div>
                            <div className="meetingModalEditViewActionButtons">
                                {/* meetingEditButton */}
                                <div className="meetingEditButton" onClick={handleDeleteModalNoButton}>
                                    No
                                </div>
                                <div className="meetingCancelButton" onClick={handleDeleteModalYesButton}>
                                    Yes
                                </div>
                            </div>
                        </div>
                    </Modal>
                </>
            )}

        </div>
    );
};

export default MeetingsPageContainer;