import React, { useState } from "react";
import InviteBusinesses from "./InviteBusinesses";
import BusinessList from "./BusinessList";

const InviteNavigation = ({name}) => {
  const [currentPage, setCurrentPage] = useState("invite"); // "invite" or "list"
  const [startingTab, setStartingTab] = useState("completed"); // Set default to completed


  const handleSendInvitation = () => {
    setCurrentPage("list");
    setStartingTab("completed"); // Default tab is completed on send
  };

  const handleAddEmail = () => {
    setCurrentPage("list");
    setStartingTab("incomplete"); // Land on incomplete tab after adding another email
  };

  const handleBackClick = () => {
    setCurrentPage("invite");
  };

  const handleInviteClick = () => {
    setCurrentPage("invite");
  };

  return (
    <div>
      {currentPage === "invite" && <InviteBusinesses onSendInvitation={handleSendInvitation}  name={name}/>}
      {currentPage === "list" && <BusinessList onBackClick={handleBackClick} startingTab={startingTab}  onInviteClick={handleInviteClick} name={name} />}
    </div>
  );
};

export default InviteNavigation;
