import React from 'react';


function AccountCreation({title, subtitle, extlink, ext}) {
  return (
    <div className="account-creation">
      <div className="account-logo">
        {/* <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" /> */}
      <h1 className="account-title">{title}</h1>
      <p className="account-subtitle">
        {subtitle} <a href={extlink}>{ext}</a>
      </p>
      </div>
    </div>
  );
}

export default AccountCreation;