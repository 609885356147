import React from 'react';
import { ConfigProvider, Steps } from 'antd';

const items = [
  { title: 'Enter your email address' },
  { title: 'Provide basic info' },
  { title: 'Create your password' },
];

const Progressstep = ({ current }) => (
  <div className="parent-container">
    <div className='progress-steps'>
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              colorPrimary: '#F2C41E',
              colorBorderBg: '#ffffff',
            },
          },
        }}
      >
        <Steps current={current} labelPlacement="vertical" items={items} />
      </ConfigProvider>
    </div>
  </div>
);

export default Progressstep;
