import React from 'react';

const SearchBar = ({ onBack, onNewMeeting }) => {
  return (
    <div className="header-container">
      <button className="back-button" onClick={onBack}>
        <span className="back-arrow">←</span> Back
      </button>
      <input
        type="text"
        className="search-input"
        placeholder="Search by company name"
      />
      <button className="new-meeting-button" onClick={onNewMeeting}>
        New meeting
      </button>
    </div>
  );
};

export default SearchBar;
