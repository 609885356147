import React from 'react';

const MeetingsTable = ({ meetings, onViewMeeting }) => {
    return (
        <table className="assessment-table">
            <thead>
                <tr className="table-header">
                    <th>Meeting time</th>
                    <th>Title</th>
                    <th>Company name</th>
                    <th>Participants</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {meetings.map((meeting, index) => (
                    <tr key={index} className="each-cell">
                        <td className="meet-time">{meeting.time}</td>
                        <td>{meeting.title}</td>
                        <td className="company-cell">{meeting.company}</td>
                        <td>{meeting.participants}</td>
                        <td>
                            <button onClick={() => onViewMeeting(meeting)}>View</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default MeetingsTable;




