import React from 'react';

const ProjectsTable = ({ projects, onViewClick }) => {
    return (
        <table className="assessment-table">
            <thead>
                <tr className="table-header">
                    <th>Clients Name</th>
                    <th>Project Title</th>
                    <th>Due Date</th>
                    <th>Price</th>
                    <th>Participants</th>
                    <th>Level</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {projects.map((project, index) => (
                    <tr key={index} className="each-cell">
                        <td className="company-cell">{project.client}</td>
                        <td>{project.title}</td>
                        <td className="due-cell">{project.dueDate}</td>
                        <td className="price-cell">{project.price}</td>
                        <td>{project.participants}</td>
                        <td>{project.level}</td>
                        <td>
                            <button onClick={() => onViewClick(project)}>View</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ProjectsTable;
