import React, { useEffect, useState } from "react";

import "../../../CSS/meetings/MeetingCard.css";

const MeetingCard = ({ meeting_id, meeting_index, meeting, openViewMeetingModalCallback }) => {
    const openViewMeetingModal = () => {
        openViewMeetingModalCallback(meeting_id, meeting_index, meeting);
    }

    const [displayMeeting, setDisplayMeeting] = useState({
        start_time: "",
        date: "",
        title: "",
        company_name: "",
        participants: ""
    });

    useEffect(() => {
        let updatedMeetingDisplay = {
            start_time: "",
            date: "",
            title: "",
            company_name: "",
            participants: ""
        };
        // Only call fetchData once, ensuring no duplicate calls or errors.
        const handleDataWithoutErrors = () => {
            if (meeting) {
                if (meeting.start_time) {
                    updatedMeetingDisplay.start_time = meeting.start_time;
                }
                if (meeting.date) {
                    updatedMeetingDisplay.date = meeting.date;
                }
                if (meeting.title) {
                    updatedMeetingDisplay.title = meeting.title;
                }
                if (meeting.user && meeting.user.name) {
                    updatedMeetingDisplay.company_name = meeting.user.name;
                }
                if (meeting.participants && Array.isArray(meeting.participants)) {
                    const totalNumberOfParticipants = meeting.participants.length;
                    if (totalNumberOfParticipants >= 1) {
                        if (meeting.participants[0].user && meeting.participants[0].user.name) {
                            updatedMeetingDisplay.participants = meeting.participants[0].user.name;
                        }
                        const remaining_participants_count = totalNumberOfParticipants - 1;
                        if (remaining_participants_count > 0) {
                            const current_value = updatedMeetingDisplay.participants;
                            updatedMeetingDisplay.participants = `${current_value} + ${remaining_participants_count}`;
                        }

                    }
                }
            }
            // Set the new state after processing
            setDisplayMeeting(updatedMeetingDisplay);
        };

        handleDataWithoutErrors();
    }, []);

    return (
        <div className="meetingsCardContainer">
            <div className="meetingTimeColumn">{displayMeeting.start_time} | {displayMeeting.date}</div>
            <div className="meetingTitleColumn">{displayMeeting.title}</div>
            <div className="meetingCompanyNameColumn">{displayMeeting.company_name}</div>
            <div className="meetingParticipantsNamesColumn">{displayMeeting.participants}</div>
            <div className="meetingActionButtons" onClick={openViewMeetingModal}>View</div>
        </div>
    );
};

export default MeetingCard;