import React, { useState } from "react";
import SignupForm from "./Forms";
import { useNavigate } from "react-router-dom";

function EmailForgot() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    function handleLoginFinish(values) {
        console.log("hanish is *** ", values);
        sendOtp(values);
        setEmail(values.email);  // Store the email in state
        navigate("/Otp", { state: { email: values.email } }); // Pass email as state
    }

    const formItems = [
        { label: "What is your email?", name: "email", rules: [{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email address' }] },
    ];

    return (
        <SignupForm 
          formItems={formItems}
          onFinish={handleLoginFinish}
          buttonText="Get OTP"
        />
    );
}

const sendOtp = async (data) => {
    console.log("hani *** ", data);
    try {
        const response = await fetch('https://api.anvillist.com/api/v1/password/request-otp', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();
        console.log('Success:', result);
        // Handle the success case, e.g., show a success message or redirect
    } catch (error) {
        console.error('Error:', error);
        // Handle the error case, e.g., show an error message
    }
};

export default EmailForgot;
