import React, { useState, useEffect } from 'react';

const MeetingDetail = ({ meeting, goBackToList, saveMeeting }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState(meeting);

  // Ensure the form initializes with the correct data when meeting changes
  useEffect(() => {
    setEditData(meeting);
  }, [meeting]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    saveMeeting(editData);
    setIsEditing(false);
  };

  return (
    <div className="form-container">
      <div className="sendheader">
        <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
        <h2 className="form-title">Invite Businesses</h2>
        <p className="form-subtitle">
          Send an invite to clients or partners for a meeting.
        </p>
      </div>
      <div className="meeting-detail">
        {!isEditing ? (
          <div>
            <div className="A1">
              <strong>Meeting title:</strong>
              <p>{editData.meetingTitle}</p>
            </div>
            <div>
              <strong>Add emails separated by commas:</strong>
              <p>{editData.emails}</p>
            </div>
            <div>
              <strong>Date of meeting:</strong>
              <p>{new Date(editData.date).toLocaleDateString('en-GB')}</p>
            </div>
            <div className="time-container">
              <div>
                <strong>Meeting start time:</strong>
                <p>{editData.startTime}</p>
              </div>
              <div>
                <strong>Meeting end time:</strong>
                <p>{editData.endTime}</p>
              </div>
            </div>
            <div>
              <strong>Description:</strong>
              <p>{editData.description}</p>
            </div>
            <button onClick={handleEdit} className="edit-btn">
              Edit
            </button>
          </div>
        ) : (
          <div>
            <label>Meeting title:</label>
            <input
              type="text"
              name="meetingTitle"
              value={editData.meetingTitle}
              onChange={handleChange}
            />

            <label>Add emails separated by commas:</label>
            <input
              type="text"
              name="emails"
              value={editData.emails}
              onChange={handleChange}
            />

            <label>Date of meeting:</label>
            <input
              type="date"
              name="date"
              value={editData.date}
              onChange={handleChange}
            />

            <label>Meeting start time:</label>
            <input
              type="text"
              name="startTime"
              value={editData.startTime}
              onChange={handleChange}
            />

            <label>Meeting end time:</label>
            <input
              type="text"
              name="endTime"
              value={editData.endTime}
              onChange={handleChange}
            />

            <label>Description:</label>
            <textarea
              name="description"
              value={editData.description}
              onChange={handleChange}
            ></textarea>

            <button onClick={handleSave} className="save-btn">
              Save
            </button>
          </div>
        )}
        <button onClick={goBackToList} className="back-btn">
          Back to Meetings
        </button>
      </div>
    </div>
  );
};

export default MeetingDetail;
