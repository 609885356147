import React from 'react';
import AccountCreation from './AccountCreation';

function Forgot()
{
    
    return (
        <AccountCreation 
        title="Forgot Password"
        subtitle="Do not remember your password?"
        extlink="/login"
        ext="Log in."/>

    );
} 


export default Forgot;