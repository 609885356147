import React from 'react';

const Card = ({ imgUrl,title, description, buttonText, onClick }) => {
    return (
        <div className="card">
            <div className='card-image'>
                <img src={imgUrl} alt={`${title}`}/>
            </div>
            <div className ='card-content'>
                <h3>{title}</h3>
                <p>{description}</p>
                <button onClick={onClick}>{buttonText}</button>
            </div>
        </div>
       
    );
};

export default Card;
