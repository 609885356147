import React from "react";
import SignupForm from "./Forms";
import { useLocation , useNavigate} from "react-router-dom";

function ForgetForm() {
    const location = useLocation();
    const navigate = useNavigate();
    const email = location.state?.email || ""; // Retrieve the email from state

    function handleLoginFinish(values) {
        console.log("hanish*** ", values);
        const dataWithEmail = { ...values, email }; // Include the email in the data sent to the API
        resetPassword(dataWithEmail);
    }

    const resetPassword = async (data) => {
        console.log("hani *** ", data);
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/password/reset', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Success:', result);
            // Handle the success case, e.g., show a success message or redirect
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            // Handle the error case, e.g., show an error message
        }
    };


  const formItems = [
    { label: "Enter OTP from your mail?", name: "otp", rules: [{ required: true, message: 'Please enter your OTP' }, { pattern: /^[A-Za-z0-9]{6}$/, message: 'Please enter valid' }] },
    { label: "Enter your new password", value:'pwdcheck', name: "password", rules: [{ required: true, message: 'Please enter your  new password' },
    // {
    //   pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
    //   message: 'Password must have following criteria'
    // }
  ] },
    { label: "Confirm new Password", name: "confirmPassword", rules: [{ required: true, message: 'Please confirm new password' },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Passwords do not match!'));
      }
    })] }
  ];
  

  return (
    <SignupForm 
      formItems={formItems}
      onFinish={handleLoginFinish}
      buttonText="Reset Password"
    />
  );
};

export default ForgetForm;
