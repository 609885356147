import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import HeaderDash from './HeaderDash';
import ProjectToolbar from './projectToolbar';
import ProjectTable from './ProjectsTable';

const ManageProjects = ({ name }) => {
  const location = useLocation();
  const isProjectsRoute = location.pathname === '/projects';
  const [view, setView] = useState(isProjectsRoute ? 'dashboard' : 'form');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDetailsPanelVisible, setDetailsPanelVisible] = useState(false);
  const [taskCompletion, setTaskCompletion] = useState({}); // State to track task completion

  // Keep the original projects array for the table
  const projects = [
    { client: 'Seedson Ltd', title: 'Market Research', dueDate: '12-Jun-2024', price: '2300 CAD', participants: '4 people', level: '50%' },
    { client: 'Brendy Ltd', title: 'Product Launch Plan', dueDate: '15-Jun-2024', price: '5000 CAD', participants: '3 people', level: '30%' },
    { client: 'Karsen Ltd', title: 'Financial Analysis', dueDate: '20-Jun-2024', price: '4000 CAD', participants: '5 people', level: '70%' },
  ];

  // New state to hold the detailed project information
  const [projectDetails, setProjectDetails] = useState({
    title: '',
    owner: '',
    description: '',
    skills: [],
    milestones: [],
    paymentType: '',
  });

  const handleTaskCompletion = (milestoneIndex, taskIndex) => {
    const taskKey = `${milestoneIndex}-${taskIndex}`;
    
    setTaskCompletion(prevState => ({
      ...prevState,
      [taskKey]: !prevState[taskKey], // Toggle the completion status
    }));
  };
  

  const handleCreateProject = (e) => {
    e.preventDefault();
    setProjectDetails({
      title: e.target.title.value,
      owner: e.target.owner.value,  // Capturing the Project Owner's email
      description: e.target.description.value,
      skills: e.target.skills.value.split(',').map(skill => skill.trim()),
      milestones: [
        {
          name: e.target.milestone.value,
          tasks: [e.target.task.value],
          budget: e.target.budget.value
        }
      ],
      paymentType: e.target.paymentType.value,
    });
    setView('dashboard');
  };

  const handleBackClick = () => {
    setView('form');
  };

  const handleCreateProjectClick = () => {
    setView('form');
  };

  const handleViewClick = (project) => {
    setSelectedProject(project);
    setDetailsPanelVisible(true);
  };

  const handleBackToDashboard = () => {
    setSelectedProject(null);
    setDetailsPanelVisible(false);
  };

  // Function to toggle task completion
  const toggleTaskCompletion = (milestoneIndex, taskIndex) => {
    setTaskCompletion(prevState => ({
      ...prevState,
      [`${milestoneIndex}-${taskIndex}`]: !prevState[`${milestoneIndex}-${taskIndex}`],
    }));
  };

  return (
    <>
      {view === 'form' ? (
        <div className="manage-projects-page">
          <div className="header-create">
            <h1>Create projects</h1>
            <p>Create projects and assign them to your team of professionals.</p>
          </div>
          <form className="manage-projects-form" onSubmit={handleCreateProject}>
            <div className="form-group">
              <label>Project title?</label>
              <input type="text" name="title" placeholder="Enter project title" />
            </div>
            <div className="form-group">
              <label>Project Owner's email</label>
              <input type="email" name="owner" placeholder="Enter project Owner's email" />
            </div>
            <div className="form-group">
              <label>Project description</label>
              <textarea name="description" placeholder="Enter project description"></textarea>
            </div>
            <div className="form-group">
              <label>
                Skills needed <button type="button" className="add-skill-button">+</button>
              </label>
              <input type="text" name="skills" placeholder="Enter required skills (comma-separated)" />
            </div>
            <div className="form-group row">
              <div className="inline-labels">
                <span className="due-date">
                  <i className="icon-calendar"></i> Due date
                </span>
                <span className="assign-members">
                  <i className="icon-user"></i> Assign
                </span>
              </div>
              <div className="form-group-full">
                <label>Milestone</label>
                <div className="input-group">
                  <input type="text" name="milestone" placeholder="Enter milestone" />
                </div>
              </div>
            </div>
            <div className="form-grouptask-row">
              <div className="form-group-half">
                <div className="task-input-group">
                  <label>Task</label>
                  <input type="text" name="task" className="task-input" placeholder="Enter task" />
                </div>
                <div className="budget-input-group">
                  <label>Budget</label>
                  <input type="text" name="budget" className="budget-input" placeholder="Enter budget" />
                </div>
              </div>
              <div className="taskbutton-row">
                <button type="button" className="add-task-button">Add new milestone</button>
                <button type="button" className="add-task-button">Add new task</button>
              </div>
            </div>
            <div className="form-group">
              <label>Payment type</label>
              <select name="paymentType">
                <option>Offline</option>
                <option>Online</option>
              </select>
            </div>
            <button type="submit" className="create-project-button">Create project</button>
          </form>
        </div>
      ) : (
        <>
          <ProjectToolbar onBackClick={handleBackClick} onCreateProjectClick={handleCreateProjectClick} />
          <div className="totalassess-container">
            <div className="assess-container">
              <ProjectTable projects={projects} onViewClick={handleViewClick} />
            </div>
          </div>

          {/* Updated Project Details Panel */}
          <div className={`details-panel ${isDetailsPanelVisible ? 'visible' : ''}`}>
            <div className="details-header">
              <img src="/images/image.png" alt="Project Icon" className="project-details-image" />
              <h2>Project Details</h2>
              <button className="back-button" onClick={handleBackToDashboard}>Back</button>
            </div>

            {selectedProject && (
              <div className="details-content">

                {/* Flexbox layout for Project Title and Project Owner */}
                <div className="project-details-header">
                  <div className="project-title-section">
                    <h3 className="project-title-label">Project title</h3>
                    <p className={`project-title-input ${selectedProject.title ? 'no-border' : ''}`}>
                      {selectedProject.title}
                    </p>
                  </div>

                  <div className="project-owner-section">
                    <h3>Project Owner</h3>
                    <p>{projectDetails.owner}</p>
                  </div>
                </div>

                <h3>Project description</h3>
                <p>{projectDetails.description}</p>

                {/* Skills needed */}
                <h3>Skills needed</h3>
                <div className="skills-container">
                  {projectDetails.skills.map((skill, index) => (
                    <div key={index} className="skill-rectangle-container">
                      <span className="skill-rectangle-text">{skill}</span>
                    </div>
                  ))}
                </div>

                {/* Milestones Section */}
                <h3>Milestones</h3>
                {projectDetails.milestones.map((milestone, index) => (
                <div key={index} className="milestone-container">
                  <h4>{milestone.name}</h4>
                  <ul className="task-list">
                    {milestone.tasks.map((task, taskIndex) => (
                      <li key={taskIndex} className="task-item">
                        <input
                          type="checkbox"
                          checked={taskCompletion[`${index}-${taskIndex}`] || false} // Default to false if not defined
                          onChange={() => handleTaskCompletion(index, taskIndex)}
                        />
                        <span className={`task-text ${taskCompletion[`${index}-${taskIndex}`] ? 'completed-task' : ''}`}>
                          {task}
                        </span>
                      </li>
                    ))}
                  </ul>
                  {milestone.budget && (
                    <p className="budget-text">Budget: {milestone.budget}</p>
                    
                  )}
                </div>
              ))}

              <div className="form-group">
              <h3> Add attachments</h3>
                <div className="attachment-dropzone" onClick={() => document.getElementById('file-upload').click()}>
                  <span className="drop-text">Drop documents +</span>
                  <input
                    type="file"
                    id="file-upload"
                    name="attachments"
                    multiple
                    className="drop-documents"
                  />
                </div>
              </div>





                
               
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ManageProjects;
