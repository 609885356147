import React, { useState } from 'react';
import AssessmentTable from './AssessmentTable'; 
import NewProfessional from './NewProfessional'; 
import { useNavigate } from 'react-router-dom'; // Import useNavigate instead of useHistory

const Workforce = () => {
    const [currentView, setCurrentView] = useState('workforce'); 
    const [selectedProfessional, setSelectedProfessional] = useState(null); // State to track selected professional
    const navigate = useNavigate(); // Use useNavigate for navigation

    const assessments = [
        {
            date: '12-Jun-2024',
            company: 'Andrea Maison',
            country: 'Canada',
            industry: 'Digital Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Brendy Ltd',
            country: 'Canada',
            industry: 'Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Meech Merch Ltd',
            country: 'Canada',
            industry: 'Product Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Seedson Ltd',
            country: 'Canada',
            industry: 'Digital Marketer',
        },
        {
            date: '12-Jun-2024',
            company: 'Brendy Ltd',
            country: 'Canada',
            industry: 'Growth',
        },
        {
            date: '12-Jun-2024',
            company: 'Meech Merch Ltd',
            country: 'Canada',
            industry: 'Sales',
        },
    ];

    const handleBackClick = () => {
        window.history.back();
    };

    const onNewProfessional = (professional) => {
        setSelectedProfessional(professional); 
        setCurrentView('newProfessional'); 
    };

    const goBackToWorkforce = () => {
        setCurrentView('workforce'); 
        setSelectedProfessional(null);
    };

    const handleProfileRedirect = () => {
        navigate('/recommended-profile'); // Use navigate to redirect
    };

    if (currentView === 'newProfessional' && selectedProfessional) {
        return (
            <div style={{ marginTop: '50px', padding: '0 20px' }}>
                <NewProfessional professional={selectedProfessional} onBack={goBackToWorkforce} />
            </div>
        );
    }

    return (
        <div style={{ marginTop: '50px', padding: '0 20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                    <button className="back-button" onClick={handleBackClick}>
                        &larr; <span>Back</span>
                    </button>
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search by profession or name"
                            className="search-inputproject" 
                        />
                    </div>
                </div>

                {/* View Button for Andrea Maison */}
                <button 
                className="view-btn" 
                onClick={() => onNewProfessional({})} // Changed this line
                style={{ bottom: '15px', padding: '12px 18px', marginRight: '40px' }}
            >
                New Professional
            </button>
        </div>
            <div style={{ width: '1195px', height: '651px', backgroundColor: '#f5f5f5', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', opacity: '1', margin: '0 auto' }}>
                <AssessmentTable assessments={assessments} />
            </div>
        </div>
    );
};

export default Workforce;

