import './App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Alert } from 'antd';
import Header from "./Components/signupComp/Header";
import HeaderDash from "./Components/Dashboard/HeaderDash";
import Progressstep from './Components/signupComp/Progressstep';
import SignupForm from './Components/signupComp/Forms';
import Signup from './Components/signupComp/Signup';
import Login from './Components/signupComp/login';
import LoginForm from './Components/signupComp/Loginform';
import Forgot from './Components/signupComp/forgot';
import ForgetForm from './Components/signupComp/forgotForm';
import EmailForgot from './Components/signupComp/forgotEmailForm';
import Dashboard from './Components/DashboardAsssemble';
import BookMeetings from './Components/Dashboard/bookmeetingbutton';
import BusinessList from './Components/Dashboard/BusinessList';
import ManageProjects from './Components/Dashboard/manageproject';
import Workforce from './Components/Dashboard/workforce'; 
import RecommendedProfessionalProfilePage from './Components/Dashboard/RecommendedProfessionalProfilePage';
import MeetingsPageContainer from './Components/Dashboard/meetings/MeetingsPageContainer';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState(""); 
    const [currentStep, setCurrentStep] = useState(0);
    const [inputValues, setInputValues] = useState({});
    const [industryValue, setIndustryValue] = useState("");
    const [user_details, setUserDetails] = useState({});

    const location = useLocation();

    
    const isDashboardRoute = /^\/(dashboard|meetings|assessment|projects|workforce|recommended-profile)/i.test(location.pathname);

    
    const shouldShowDashboardHeader = isLoggedIn && isDashboardRoute; // Show `HeaderDash` only for authenticated users on dashboard routes
    const shouldShowDefaultHeader = !shouldShowDashboardHeader; // Show `Header` in all other cases

    const handleLogin = (name, user_details) => {
        setIsLoggedIn(true);
        setUserName(name); 
        setUserDetails(user_details);
        console.log("user details = ", user_details);
    };

    const handleFormFinish = (values) => {
        if (currentStep < formItems.length - 1) {
            setCurrentStep(currentStep + 1);
        }
        setInputValues((prevValues) => {
            const firstName = values.firstName || prevValues.firstName || "";
            const lastName = values.lastName || prevValues.lastName || "";
            const fullName = `${firstName} ${lastName}`.trim();
            const allValues = {
                ...prevValues,
                ...values,
                name: fullName,
                type: "Professional",
                industry: industryValue,
            };
            if (currentStep === 2) {
                postSignupData(allValues);
            }
            return allValues;
        });
    };

    const postSignupData = async (data) => {
        try {
            const response = await fetch('https://api.anvillist.com/api/v1/register', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                if (response.status === 422) {
                    alert("The email has already been taken.");
                }
                throw new Error('Network response was not ok');
            }
            const result = await response.json();
            window.location.href = '/login';
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleChildData = (data) => {
        setIndustryValue(data);
    };

    const formItems = [
        { label: "What is your email?", name: "email", rules: [{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email address' }] },
        { label: "What is your first name?", name: "firstName", rules: [{ required: true, message: 'Please enter your first name' }] },
        { label: "What is your last name?", name: "lastName", rules: [{ required: true, message: 'Please enter your last name' }] },
        { label: "What is your mobile number?", name: "phone", rules: [{ required: true, message: 'Please enter your Mobile Number' }, { pattern: /^[0-9]{10}$/, message: 'Please enter 10 digits' }] },
        { label: "What is your business name?", name: "business_name", rules: [{ required: true, message: 'Please enter your business name' }] },
        { label: "What industry does your business belong to?", name: "industry", rules: [{ required: false, message: 'Please enter your business industry' }] },
        {
            label: "Create your password",
            value: 'pwdcheck',
            name: "password",
            rules: [
                { required: true, message: 'Please create your password' },
                { min: 8, message: 'Password must have following criteria' },
            ]
        },
        {
            label: "Confirm password", name: "confirmPassword", rules: [
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('Passwords do not match!'));
                    }
                })
            ]
        },
    ];

    const { itemsToShow, buttonText } = formslice();

    function formslice() {
        let itemsToShow = [];
        let buttonText = 'Next';
        if (currentStep === 0) {
            itemsToShow = [formItems[0]];
        } else if (currentStep === 1) {
            itemsToShow = formItems.slice(1, 6);
        } else {
            itemsToShow = formItems.slice(6, 8);
            buttonText = 'Create My Account';
        }
        return { itemsToShow, buttonText };
    }

    return (
        <>
            {shouldShowDefaultHeader && <Header />}
            {shouldShowDashboardHeader && <HeaderDash name={userName} />}

            <Routes>
                <Route
                    path="/"
                    element={<><Login /><LoginForm onLogin={handleLogin} /></>}
                />
                <Route
                    path="/login"
                    element={<><Login /><LoginForm onLogin={handleLogin} /></>}
                />
                <Route
                    path="/signup"
                    element={<><Signup /><Progressstep current={currentStep} /><SignupForm formItems={itemsToShow} sendDataToParent={handleChildData} onFinish={handleFormFinish} buttonText={buttonText} /></>}
                />
                <Route
                    path="/forgot-password"
                    element={<><Forgot /><EmailForgot /></>}
                />
                <Route
                    path="/otp"
                    element={<><Forgot /><ForgetForm /></>}
                />
                <Route
                    path="/dashboard"
                    element={<Dashboard userName={userName} />}
                />
                {/* <Route path="/meetings" element={<BookMeetings />} /> */}
                <Route path="/meetings" element={<MeetingsPageContainer user_details={user_details} />} />
                <Route path="/assessment" element={<BusinessList startingTab="completed" />} />
                <Route path="/projects" element={<ManageProjects />} />
                <Route path="/workforce" element={<Workforce />} />
                <Route path="/recommended-profile" element={<RecommendedProfessionalProfilePage />} />
            </Routes>
        </>
    );
}

export default App;
