import React from 'react';
import AccountCreation from './AccountCreation';

function Login()
{
    return (
        <AccountCreation
        title="Welcome Back"
        subtitle="Do not have an account?"
        extlink="/signup"
        ext="Sign up."/>

    );
} 

export default Login