import React from 'react';
import ReactDOM from 'react-dom/client';
import './CSS/index.css';
import './CSS/Dashboard.css';
import './CSS/insideDash.css';
import './CSS/createProject.css';
import './CSS/BookMeeting.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as  Router} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
            <App />
  </Router>
);
reportWebVitals();
