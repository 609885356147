import React, { useState } from 'react';

const TabNavigation = ({ tabs, children }) => {
    const [activeTab, setActiveTab] = useState(tabs[0]);

    return (
        <div className="tab-navigation">
            <ul className="tab-list">
                {tabs.map((tab) => (
                    <li key={tab} className={activeTab === tab ? 'active' : ''} onClick={() => setActiveTab(tab)}>
                        {tab}
                    </li>
                ))}
            </ul>
            <div className="tab-content">
                {children[activeTab]}
            </div>
        </div>
    );
};

export default TabNavigation;
