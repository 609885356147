import React from 'react';
  

function Header()
{
    return (
    <header className="header">
      <div className="logo">
        <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
      </div>
      <nav className="nav">
        <ul>
          <li><a href="/signup">Sign up</a></li>
          <li>|</li>
          <li><a href="/login">Login</a></li>
        </ul>
      </nav>
    </header>
    );

}
export default Header;