import React from 'react';
import { Card, Avatar, Typography, Tag } from 'antd';
import { useNavigate } from "react-router-dom";
import '../../CSS/RecommendedProfessionalProfile.css';
import userProfilePhoto from '../../assets/randomUserImage.jpeg';
import backButtonImage from '../../assets/backButtonImage.png';

const { Title, Paragraph } = Typography;

const RecommendedProfessionalProfile = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    const experienceData = [
        {
            title: 'Digital Marketing Manager',
            company: 'ABC Marketing Solutions',
            period: '2020 – Present',
            description: 'Developed and executed multi-channel digital marketing strategies, increasing organic traffic by 50% and sales by 30%. Led a team of 5 specialists and aligned marketing efforts with business objectives through collaboration with cross-functional teams.'
        },
        {
            title: 'SEO Specialist',
            company: 'XYZ Digital Agency',
            period: '2018 – 2020',
            description: 'Performed SEO audits, optimized websites, and developed link-building strategies, boosting organic search traffic by 40%. Provided insights through Google Analytics and SEMrush to enhance user experience and conversion rates.'
        }
    ];

    const skills = [
        'SEO',
        'Digital Marketing',
        'Content Marketing',
        'Social Media Management'
    ];

    return (
        <div className="profile-page">
            <div className="profile-page__back-button">
                <button onClick={handleBackClick}>
                    <img src={backButtonImage} alt="Back" />
                    <span>Back</span>
                </button>
            </div>

            <div className="profile-page__content">
                {/* Profile Card */}
                <Card className="profile-card">
                    <div className="profile-card__header">
                        <div className="profile-card__user-info">
                            <Avatar size={100} src={userProfilePhoto} />
                            <div className="profile-card__user-details">
                                <Title level={3}>Andrea Maison</Title>
                                <Paragraph>Digital Marketer</Paragraph>
                            </div>
                        </div>

                        {/* First Divider Line */}
                        <div className="profile-card__divider"></div>

                        <div className="profile-card__contact-info">
                            <div className="contact-section">
                                <Title level={3}>Contact</Title>
                                <Paragraph>andrea.m@gmail.com</Paragraph>
                                <Paragraph>+17648384094</Paragraph>
                            </div>
                            
                            {/* Second Divider Line */}
                            <div className="profile-card__divider"></div>

                            <div className="compensation-section">
                                <Title level={3}>Compensation</Title>
                                <Paragraph>$70/hr</Paragraph>
                            </div>
                        </div>
                    </div>

                    <div className="profile-card__about">
                        <Title level={4}>About Me</Title>
                        <Paragraph>
                            Andrea Maison is a seasoned digital marketer with over six years of experience driving brand 
                            awareness, engagement, and conversions through innovative strategies. She has a strong background 
                            in SEO, content marketing, social media management, and PPC advertising, managing campaigns for 
                            a diverse portfolio of clients from startups to established businesses.
                        </Paragraph>
                    </div>
                </Card>

                {/* Skills Card */}
                <Card className="skills-card">
                    <Title level={4}>Skills</Title>
                    <div className="skills-card__tags">
                        {skills.map((skill, index) => (
                            <Tag key={index}>{skill}</Tag>
                        ))}
                    </div>
                </Card>

                {/* Experience Card */}
                <Card className="experience-card">
                    <Title level={4}>Professional Experience</Title>
                    <div className="experience-card__content">
                        {experienceData.map((experience, index) => (
                            <div key={index} className="experience-item">
                                <Title level={5}>
                                    {experience.title} ({experience.company}, {experience.period})
                                </Title>
                                <Paragraph>{experience.description}</Paragraph>
                            </div>
                        ))}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default RecommendedProfessionalProfile;




