import React, { useState } from "react";
import SignupForm from "./Forms";
import { useNavigate } from "react-router-dom";
import "../.././CSS/LoginForm.css";

function LoginForm({ onLogin }) {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(""); 
  const navigate = useNavigate();

  const handleLoginFinish = (values) => {
    postLogin(values);
  };

  const postLogin = async (data) => {
    try {
        const response = await fetch('https://api.anvillist.com/api/v1/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (!response.ok) {
          if (response.status === 422) {
            setLoginError("Invalid credentials");
            alert("Invalid credentials");
          } else {
            throw new Error('Network response was not ok');
          }
          return;
        }
        
        const result = await response.json();
        console.log("result in login is = ", result);
        setLoginSuccess(true);
        const userName = result.data.name; 
        // sessionStorage.setItem("user_detail_access_token", result.access_token);
        onLogin(userName, result); 
        navigate('/Dashboard', { state: { result } });

    } catch (error) {
        console.error('Error:', error);
        setLoginError("An error occurred. Please try again.");
    }
  };

  const formItems = [
    {
      label: "What is your email?",
      name: "email",
      rules: [
        { required: true, message: 'Please enter your email' },
        { type: 'email', message: 'Please enter a valid email address' },
      ],
    },
    {
      label: "Password",
      name: "password",
      rules: [{ required: true, message: 'Please enter your password' }],
    },
  ];
  
  return (
    <>
   
      <div className="login-form-container">
        {loginError && <div className="error-message">{loginError}</div>}
        <SignupForm 
          formItems={formItems}
          onFinish={handleLoginFinish}
          buttonText="Log in"
        />
      </div>
    </>
  );
}

export default LoginForm;

