import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AssessmentTable = ({ assessments }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Extract the name from the location state
    const name = location.state?.name || 'Default Name';

    const handleViewClick = (company) => {
        if (company === 'Andrea Maison') {
            navigate('/recommended-profile', { state: { name } });
        }
        // Add other company handling logic here if needed
    };

    return (
        <table className="assessment-table">
            <thead>
                <tr className="table-header">
                    <th>Date</th>
                    <th>Company</th>
                    <th>Country</th>
                    <th>Industry</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {assessments.map((assessment, index) => (
                    <tr key={index} className="each-cell">
                        <td className="date-cell">{assessment.date}</td>
                        <td className="company-cell">{assessment.company}</td>
                        <td>{assessment.country}</td>
                        <td>{assessment.industry}</td>
                        <td>
                            <button onClick={() => handleViewClick(assessment.company)}>
                                View
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AssessmentTable;
