import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from 'react-router-dom'; 

const BusinessList = ({ name, startingTab, onInviteClick }) => {
  const [tab, setTab] = useState(startingTab);
  const navigate = useNavigate();
  const location = useLocation(); 
  
  useEffect(() => {
    setTab(startingTab); // Update tab if startingTab changes
  }, [startingTab]);

  const businesses = {
    completed: [
      { date: "12-Jun-2024", company: "Seedson Ltd", country: "Canada", industry: "Health care" },
      { date: "12-Jun-2024", company: "Brendy Ltd", country: "Canada", industry: "Telecoms" },
      { date: "12-Jun-2024", company: "Karsen Ltd", country: "Canada", industry: "Finance" },
      { date: "12-Jun-2024", company: "Meech Merch Ltd", country: "Canada", industry: "Oil & Gas" },
    ],
    incomplete: [
      { date: "12-Jun-2024", email: "john.m@seedson.com", status: "Sent" },
      { date: "12-Jun-2024", email: "femi.m@krakspee.com", status: "Started" },
    ],
  };

  const handleTabChange = (tab) => {
    setTab(tab);
  };

  const handleBackClick = () => {
    if (location.pathname === '/assessmen') {
      navigate(-1); 
    } 
    
  };
  

  return (
    <>
      

      <div>
        <div className="button-group-container">

          <button onClick={handleBackClick} className="back-button">Back</button>
          <div className="status-container">
            <button onClick={() => handleTabChange("completed")} className={`tab ${tab === "completed" ? "active" : ""}`}>
              Completed
            </button>
            <span className="divider">|</span>
            <button onClick={() => handleTabChange("incomplete")} className={`tab ${tab === "incomplete" ? "active" : ""}`}>
              Incomplete
            </button>
          </div>
          <button onClick={onInviteClick} className="invite-button">Invite Business</button>
        </div>

        {tab === "completed" && (
          <div className="totalassess-container">
            <div className="assess-container">
              <table className="assessment-table">
                <tbody>
                  {businesses.completed.map((business, index) => (
                    <tr key={index}>
                      <div className='each-cell'>
                        <td className="date-cell">{business.date}</td>
                        <td className='company-cell'>{business.company}</td>
                        <td>{business.country}</td>
                        <td>{business.industry}</td>
                        <td><button className="view-button">View</button></td>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {tab === "incomplete" && (
          <div className="totalassess-container">
            <div className="assess-container">
              <table className="assessment-table">
                <thead>
                  <div className="table-header">
                    <tr>
                      <th>Date sent</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </div>
                </thead>
                <tbody>
                  {businesses.incomplete.map((business, index) => (
                    <tr key={index}>
                      <div className='each-cell'>
                        <td className="date-cell">{business.date}</td>
                        <td className='company-cell'>{business.email}</td>
                        <td>{business.status}</td>
                      </div>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BusinessList;
