import React from 'react';
import { Link } from 'react-router-dom';

const HeaderDash = ({ name }) => {
    return (
        <header className="headerdash">
            <div className="logo">
                <img src="/Images/svgviewer-png-output.png" alt="Anvil Logo" />
            </div>
            <nav className="nav-middle">
                <ul>
                    <li><Link to={{ pathname: "/Dashboard", state: { name } }}>Dashboard</Link></li>
                    <li><Link to={{ pathname: "/assessment", state: { name } }}>Assessment</Link></li>
                    <li><Link to={{ pathname: "/meetings", state: { name } }}>Meetings</Link></li>
                    <li><Link to={{ pathname: "/projects", state: { name } }}>Projects</Link></li>
                    <li><Link to={{ pathname: "/workforce", state: { name } }}>Workforce</Link></li>
                </ul>
            </nav>
            <nav className="nav-right">
                <ul>
                    <li><Link to="/business">Business</Link></li>
                    <li>|</li>
                    <li><Link to={`/${name}`}>{name}</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default HeaderDash;

