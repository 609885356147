import React from 'react';
import AccountCreation from './AccountCreation';

function Signup()
{
    return (
        <AccountCreation 
        title="Creat an account"
        subtitle="Already have an account?"
        extlink="/login"
        ext="Log in."/>

    );
} 

export default Signup;