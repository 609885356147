import React from 'react';
import "../../../CSS/meetings/MeetingCardsColumnDetail.css";

const MeetingCardsColumnDetail = () => {
    return (
        <div className="meetingCardsDetailContainer">
            <div className="headerRow">
                <div className="headerTimeColumn">Time</div>
                <div className="headerTitleColumn">Meeting Title</div>
                <div className="headerCompanyNameColumn">Company name</div>
                <div className="headerParticipantsNamesColumn">Participants</div>
            </div>
            {/* Add your existing meeting card rows here */}
        </div>
    );
}

export default MeetingCardsColumnDetail;
