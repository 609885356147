import React from "react";
import MeetingCard from "./MeetingCard";
import MeetingCardsColumnDetail from "./MeetingCardsColumnDetail";
import { Modal } from "antd";

import "../../../CSS/meetings/MeetingsList.css";

const MeetingsList = ({ dev_meetings_list, openViewMeetingModalFromMeetingCard }) => {

    const openViewMeetingModal = (id, index, incomingData) => {
        openViewMeetingModalFromMeetingCard(id, index, incomingData);
    }

    // console.log("dev_meetings_list ", dev_meetings_list);

    return (
        <div className="meetingsListContainer">
            <MeetingCardsColumnDetail />
            <div className="emptyTopSpace"></div>
            {(dev_meetings_list.length === 0) ? (
                <div className="noMeetingsListView">Engage with business experts by creating your first meeting.</div>
            ) : (
                <div className="listOfMeetingCards">
                    {dev_meetings_list.map((meeting, index) => (
                        <MeetingCard
                            key={meeting.id}
                            meeting_id={meeting.id}
                            meeting_index={index}
                            meeting={meeting}
                            openViewMeetingModalCallback={openViewMeetingModal}
                        />
                    ))}
                </div>
            )}
            <div className="emptyCardSpace"></div>
        </div >
    );
};

export default MeetingsList;