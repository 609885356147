
import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons'; // Import Ant Design's back arrow icon

const ProjectToolbar = ({ onBackClick, onCreateProjectClick }) => {
  const [activeTab, setActiveTab] = useState('All'); // State to handle the active tab

  // Tabs list
  const tabs = ['All', 'Pending', 'Accepted', 'Closed'];

  // Handler for changing active tab
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="toolbar-container">
      <div className="toolbar-left">
        {/* Back button triggers onBackClick */}
        <button className="back-button" onClick={onBackClick}>
          <ArrowLeftOutlined />
          <span>Back</span>
        </button>

        {/* Tabs for project status */}
        <div className="tabs-container">
          {tabs.map((tab) => (
            <div
              key={tab}
              className={`tab-item ${activeTab === tab ? 'active-tab' : ''}`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </div>
          ))}
        </div>

        {/* Search Input */}
        <div className="search-container">
          <input
            type="text"
            className="search-inputproject"
            placeholder="Search by project title"
          />
        </div>
      </div>

      {/* Create New Projects Button triggers onCreateProjectClick */}
      <div className="toolbar-right">
        <button className="create-project-button" onClick={onCreateProjectClick}>
          Create new projects
        </button>
      </div>
    </div>
  );
};

export default ProjectToolbar;
